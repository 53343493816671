import React, { FC, useEffect, useState } from 'react'
// mui imports
import Grid from '@mui/material/Grid'
// custom imports
import FormInputText from '../../../../utils/form/FormInputText'
import FormInputNumber from '../../../../utils/form/FormInputNumber'
import FormInputDropdown from '../../../../utils/form/FormInputDropdown'
import ApiForm from '../../../../utils/form/ApiForm'
import { api, FormSpacing } from '../../../../utils/globals'
import {AxiosError, AxiosResponse} from 'axios'
import { doAlert } from '../../../../utils/globalComps/PopupAlert/PopupAlert'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Typography from '@mui/material/Typography'
import ImportModal from '../../../../utils/modals/ImportModal/ImportModal'
import FileUpload from '@mui/icons-material/FileUpload'
import Delete from '@mui/icons-material/Delete'

interface MoldFormProps {
  id: number | undefined
  open: boolean
  closeForm: (submit: boolean, data: any, create: boolean) => void
  machines: any[]
  hasPdf?: boolean
  deletePdfInstructions: (id: number) => void
  setNewPdfInstructions: (file: any) => void
}
const MoldForm: FC<MoldFormProps> = ({id, open, closeForm, machines,
                                       hasPdf, deletePdfInstructions, setNewPdfInstructions}) => {

  const [linkedItems, setLinkedItems] = useState<any[]>([])
  const [pdfUploadOpen, setPdfUploadOpen] = useState<boolean>(false)
  const [pdfUploadModalTitle, setPdfUploadModalTitle] = useState<string>('')

  const fetchUrl: string = id ? `/inventory/molds/${id}/` : ''
  const submitUrl: string = '/inventory/molds/'

  const closeFormOverride = (submit: boolean, data: any, create: boolean) => {

    closeForm(submit, data, create)
  }

  useEffect(() => {
    let isActive = true

    console.log('mold form use effect')
    console.log('machines :', machines)

    if (id)
      api.get(`${fetchUrl}?getLinkedObjects=${true}`)
        .then((resp: AxiosResponse) => {if (isActive) setLinkedItems(resp.data.items)})
        .catch((err: AxiosError) => {if (isActive && err.response) doAlert(err.response.data.message, 'error', true)})

    return () => {isActive = false}
  }, [id, fetchUrl, open, hasPdf])

  // import modal actions
  const openImportModal = () => {
    api.get(fetchUrl)
      .then((resp: AxiosResponse) => {
        setPdfUploadModalTitle(`PDF Instructions Upload ${resp.data.object[0].name}`)
        setPdfUploadOpen(true)
      })
      .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', true)})
  }
  const closeImportModal = (uploaded: boolean, file: any) => {
    if (uploaded)
      setNewPdfInstructions(file)
    setPdfUploadOpen(false)
  }
  const generateTableActions = () => {
    if (id) {
      let actionList: any = [{text: 'Upload PDF Instructions', action: openImportModal, icon: <FileUpload />}]
      console.log('has pdf :', hasPdf)
      if (hasPdf && id)
        actionList.push({text: 'Delete PDF Instructions', action: () => deletePdfInstructions(id), icon: <Delete />})
      return actionList
    } else
      return []
  }

  return (
    <>
      <ApiForm
        name='Mold Form'
        open={open}
        defaultValues={{primary_machine_ids: []}}
        closeForm={closeFormOverride}
        id={id}
        tableActions={generateTableActions()}
        fetchUrl={fetchUrl}
        submitUrl={submitUrl}
      >
        <Grid container spacing={FormSpacing}>
          {/* <Grid item xs={4}><FormInputDropdown name='primary_machine_ids' label='Primary Machines' options={machines} multiple={true} /></Grid> */}
					{/* removed, added as its owm standalone form */}
          <Grid item xs={6}><FormInputText name='name' label='Mold Number' /></Grid>
          <Grid item xs={6}><FormInputText name='location' label='Location'/></Grid>
          <Grid item xs={3}><FormInputNumber name='in_stock' label='In Stock' /></Grid>
          <Grid item xs={3}><FormInputNumber name='in_use' label='In Use' disabled /></Grid>
          <Grid item xs={3}><FormInputNumber name='mold_yield' label='Yield' /></Grid>
          <Grid item xs={3}><FormInputNumber name='takt_time' label='Takt time' /></Grid>
          <Grid item xs={4}><FormInputNumber name='x' label='X dimension' /></Grid>
          <Grid item xs={4}><FormInputNumber name='y' label='Y dimension' /></Grid>
          <Grid item xs={4}><FormInputNumber name='z' label='Z dimension' /></Grid>
        </Grid>
        {linkedItems.length > 0 ?
          <>
            <Grid container justifyContent='center' sx={{mt: '15px'}}>
              <Typography variant='h5'>Items assigned to this Mold</Typography>
            </Grid>
            <Grid container>
              <TableContainer sx={{mb: '10px'}}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{textAlign: 'center'}}>Name</TableCell>
                      <TableCell sx={{textAlign: 'center'}}>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {linkedItems.map((item: any, key: number) => (
                      <TableRow key={key}>
                        <TableCell sx={{textAlign: 'center'}}>{item.name}</TableCell>
                        <TableCell sx={{textAlign: 'center'}}>{item.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
          : <></>}
      </ApiForm>
      <ImportModal open={pdfUploadOpen} closeHandler={closeImportModal}
                   url={fetchUrl} title={pdfUploadModalTitle} typeName='pdf'
                   appendData={{type: 'setupsheet'}} doCheck />
  </>
  )
}

export default MoldForm
