import React, {
	useEffect,
	useMemo,
	useCallback,
	useState,
	useRef,
} from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	CircularProgress,
	Box,
	Grid,
	Typography,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { PrimaryLocation } from './hooks/Interfaces';
import Autocomplete from '../../../../utils/form/fields/AutoComplete';
import usePrimarySchedule from './hooks/usePrimarySchedule';

interface PrimarySchedulingDialogProps {
	onClose: () => void
	identifier: string | number | null;
}

interface FormValues {
	primary_machine: PrimaryLocation | null;
	primary_lineplans: PrimaryLocation[] | null;
}

const PrimaryScheduleDialog: React.FC<PrimarySchedulingDialogProps> = ({
	onClose,
	identifier,
}) => {
	const {
		item,
		machines, 
		lineplans,
		primaryMachine, 
		primaryLineplans,
		sendMessage, 
		loading
	} = usePrimarySchedule(identifier);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const methods = useForm<FormValues>();
	const { handleSubmit } = methods;

	useEffect(() => {
		methods.reset({
			primary_machine: primaryMachine || null,
			primary_lineplans: primaryLineplans || null,
		});
	}, [methods, primaryMachine, primaryLineplans]);

	// Form submission
	const onSubmit = async (formData: FormValues) => {
		setIsSubmitting(true);
		try {
			sendMessage('submit_form', { form: formData });
		} finally {
			setIsSubmitting(false);
			onClose()
		}
	};

	const open = useMemo(() => identifier != null, [identifier])

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="md"
			PaperProps={{
				sx: {
					borderRadius: 5,
				},
			}}
		>
			<DialogTitle
				sx={{
					textAlign: 'center',
					fontWeight: 'bold',
					fontSize: '1.25rem',
				}}
			>
				Auto Scheduling Defaults
			</DialogTitle>

			<DialogContent
				dividers
				sx={{
					padding: 2,
				}}
			>
				{loading ? (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							minHeight: '200px',
						}}
					>
						<CircularProgress />
					</Box>
				) : (
					<FormProvider {...methods}>
						<form onSubmit={handleSubmit(onSubmit)} id="order-form">
							<Grid container spacing={3} sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
									
								<Typography fontSize={20} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
									<Box component="span" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
										{item.name}
									</Box>
									{' - '}
									<Box component="span" sx={{ color: 'text.secondary', fontSize: '1rem' }}>
										{item.description}
									</Box>
									{' : '}
									<Box component="span" sx={{ fontWeight: 'bold' }}>
										{item.type}
									</Box>
								</Typography>


								{/* First Row: Switch and Default Machine */}
								{	item.type == 'Moldable' &&
									<Grid item xs={12}>
										<Autocomplete
											fullWidth
											name="primary_machine"
											label="Primary Machine"
											options={machines}
										/>
									</Grid>
								}

								{/* Second Row: Default Lineplan */}
								<Grid item xs={12}>
										<Autocomplete
											name="primary_lineplans"
											label="Primary Lineplan"
											fullWidth
											multiple
											// Provide ALL lineplans here
											options={lineplans}
											// Use a custom filter function
											// REMOVED FILTER, some customers use shift specific lineplans and need multiple of the same type
											// filterOptions={(options, state) => {
											// 	// The current selection is stored in the form, e.g. watch("default_lineplans"):
											// 	const selected = methods.watch("primary_lineplans") || [];
											// 	// Filter out lineplans whose type matches a type in the selected array
											// 	return options.filter(opt => !selected.some(sel => sel.type === opt.type));
											// }}
										/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				)}
			</DialogContent>

			<DialogActions sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
				<Button
					onClick={onClose}
					variant="outlined"
					color="error"
					sx={{ borderRadius: 3 }}
				>
					Cancel
				</Button>
				<Button
					type="submit"
					form="order-form"
					variant="contained"
					color="primary"
					sx={{ borderRadius: 3 }}
				>
					{isSubmitting ? 'Saving...' : 'Save'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PrimaryScheduleDialog;
