import React, {
	useEffect,
	useMemo,
	useCallback,
	useState,
	useRef,
} from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	CircularProgress,
	Box,
	Grid,
	IconButton,
	Tooltip,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import useOrderForm from './hooks/useOrderForm';
import TextField from '../../../../utils/form/fields/TextField';
import Autocomplete from '../../../../utils/form/fields/AutoComplete';
import DatePicker from '../../../../utils/form/fields/DatePicker';
import debounce from 'lodash/debounce';
import NewChildOrderForm from './NewChildOrderForm';
import { BomItem } from './hooks/OrderFormInterfaces';
import { Visibility } from '@mui/icons-material';

interface NewOrderFormProps {
	open: boolean;
	onClose: () => void;
	id: number | undefined | null;
}

interface ItemOptionType {
	id: string | number;
	label: string;
	[key: string]: any;
}

interface FormValues {
	number: string;
	parent_order: ItemOptionType | null;
	item: ItemOptionType | null;
	quantity: number;
	due_date: Date | null;
	ship_date: Date | null;
}

const NewOrderForm: React.FC<NewOrderFormProps> = ({
	open,
	onClose,
	id: initialId,
}) => {
	const {
		id,
		orders,
		items,
		form,
		loading,
		orderTree,
		version,
		sendMessage,
	} = useOrderForm(initialId);

	// Determine if this is a new order based on the ID from the hook
	const isNewOrder = !id;

	// Get the button text based on whether this is a new order or edit
	const submitButtonText = useMemo(() => (isNewOrder ? 'Create' : 'Save'), [isNewOrder]);

	// Determine if modal should close after submission based on order tree depth
	const shouldCloseOnSubmit = useMemo(() => orderTree.depth === 0, [orderTree.depth]);

	const [orderNumber, setOrderNumber] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);

	// This ref tracks the "version" or "timestamp" for each validation
	const validationVersionRef = useRef(0);

	const methods = useForm<FormValues>();
	const { handleSubmit } = methods;

	const hasAnyOrderNumber = (tree: Record<string, BomItem>): boolean => {
		// If there's no tree or it's empty, return false
		if (!tree) return false;

		// Return true if *any* item in the tree has a `number` or any of its children do
		return Object.values(tree).some((item) => {
			if (item.number) {
				// This item has a `number`; no need to check further
				return true;
			}
			// Otherwise, if there are children, recursively check them
			if (item.children) {
				return hasAnyOrderNumber(item.children);
			}
			return false;
		});
	}

	// Memoized check for any existing child order. if any exist, parent form will be locked.
	const locked = useMemo(() => {
		return hasAnyOrderNumber(orderTree.tree) || form.parent_order.value != null;
	}, [orderTree.tree]);

	// controls the disabled status of Quantity, Ship Date, and Due Date since these can be adjusted after order creation despite parent/child relationships
	const granularLock = useMemo(() => form.parent_order.value != null, [form])

	// Create a stable debounced function that sends a "validate_form" message
	// - We'll pass in { form: FormValues, version: number }
	const debouncedValidate = useMemo(
		() =>
			debounce((formData: FormValues, version: number) => {
				sendMessage('validate_form', {
					form: formData,
					version,
				});
			}, 500),
		[sendMessage]
	);

	// Cancel debouncing on unmount
	useEffect(() => {
		return () => {
			debouncedValidate.cancel();
		};
	}, [debouncedValidate]);

	// Whenever the server pushes new form data (e.g., after validation),
	// we reset if it’s the "current" version. 
	useEffect(() => {
		// console.log(`version ${version} >= validationVersionRef.current ${validationVersionRef.current}`)
		if (form && version >= validationVersionRef.current) {
			// console.log('matching versions, updating form fields')
			// If the server returns up-to-date form data, apply it.
			// If it's out-of-date, you could skip resetting here.
			setOrderNumber(form.number.value);
			methods.reset({
				number: form.number.value || '',
				parent_order: form.parent_order.value || null,
				item: form.item.value || null,
				quantity: form.quantity.value || 1,
				due_date: form.due_date.value ? new Date(form.due_date.value) : null,
				ship_date: form.ship_date.value ? new Date(form.ship_date.value) : null,
			});
		}
	}, [form, methods]);

	// For each field change:
	// 1) Update local form data
	// 2) Increment version
	// 3) Pass the new version to the debounced validator
	const handleFieldChange = useCallback(
		<K extends keyof FormValues>(name: K, value: FormValues[K]) => {
			const formData = methods.getValues();
			formData[name] = value;

			// 2) Increment the version
			validationVersionRef.current += 1;

			// 3) Call the debounced validator with (formData, newVersion)
			const newVersion = validationVersionRef.current;
			console.log(`new version: ${newVersion}`)
			debouncedValidate(formData, newVersion);
		},
		[methods, debouncedValidate]
	);

	// Check if all fields have no errors
	const valid = useMemo(
		() => Object.values(form).every((field) => field.error.length === 0),
		[form]
	);

	// Form submission
	const onSubmit = async (formData: FormValues) => {
		setIsSubmitting(true);
		try {
			sendMessage('submit_form', { form: formData });
			if (valid) {
				if (isNewOrder && !shouldCloseOnSubmit) {
					// If it's a new order with children, stay open
					return;
				}
				onClose();
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="md"
			PaperProps={{
				sx: {
					borderRadius: 5,
				},
			}}
		>
			<DialogTitle
				sx={{
					textAlign: 'center',
					fontWeight: 'bold',
					fontSize: '1.25rem',
				}}
			>
				{id ? `Edit Order ${orderNumber}` : 'New Order'}
			</DialogTitle>

			<DialogContent
				dividers
				sx={{
					padding: 2,
				}}
			>
				{loading ? (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							minHeight: '200px',
						}}
					>
						<CircularProgress />
					</Box>
				) : (
					<FormProvider {...methods}>
						<form onSubmit={handleSubmit(onSubmit)} id="order-form">

							<Grid container spacing={2} sx={{ my: 1 }}>
								<Grid item xs={12} sm={6}>
									<TextField
										name="number"
										label="Order Number"
										requiredField
										errorMessage={form.number.error}
										disabled={locked}
										onValueChange={(value) => {
											handleFieldChange('number', value);
											setOrderNumber(value);
										}}
									/>	
								</Grid>
								<Grid item xs={12} sm={6}>
									<Box sx={{ position: 'relative' }}>
										<Autocomplete
											name="parent_order"
											label="Parent Order"
											options={orders}
											requiredField={false}
											disabled={locked}
											errorMessage={form.parent_order.error}
											onValueChange={(value) => handleFieldChange('parent_order', value)}
											sx={{ width: '100%' }}
										/>
										{form.parent_order.value && form.parent_order.value.id && (
											<Tooltip title="View parent order">
												<IconButton
													onClick={() =>
														sendMessage('view_parent_order', {
															parent_order_number: form.parent_order.value.label,
														})
													}
													sx={{
														position: 'absolute',
														top: '50%',
														transform: 'translateY(-50%)',
														right: 32, // adjust as needed
														zIndex: 2,
													}}
												>
													<Visibility />
												</IconButton>
											</Tooltip>
										)}
									</Box>
								</Grid>

								<Grid item xs={12} sm={6}>
									<Autocomplete
										name="item"
										label="Item"
										options={items}
										disabled={locked}
										requiredField
										errorMessage={form.item.error}
										onValueChange={(value) => handleFieldChange('item', value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										name="quantity"
										label="Quantity"
										type="number"
										requiredField
										disabled={granularLock}
										inputProps={{ min: 1 }}
										errorMessage={form.quantity.error}
										onValueChange={(value) => {
											const numValue = Number(value);
											if (numValue >= 1) {
												handleFieldChange('quantity', numValue);
											}
										}}
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<DatePicker
										name="due_date"
										label="Due Date"
										requiredField
										disabled={granularLock}
										errorMessage={form.due_date.error}
										onValueChange={(value) => handleFieldChange('due_date', value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<DatePicker
										name="ship_date"
										label="Ship Date"
										requiredField
										disabled={granularLock}
										errorMessage={form.ship_date.error}
										onValueChange={(value) => handleFieldChange('ship_date', value)}
									/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				)}
				<NewChildOrderForm orderTree={orderTree} sendMessage={sendMessage} />
			</DialogContent>

			<DialogActions sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
				<Button
					onClick={onClose}
					variant="outlined"
					color="error"
					sx={{ borderRadius: 3 }}
				>
					Cancel
				</Button>
				<Button
					type="submit"
					form="order-form"
					variant="contained"
					color="primary"
					sx={{ borderRadius: 3 }}
					disabled={loading || !valid || isSubmitting}
				>
					{isSubmitting ? 'Saving...' : submitButtonText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default NewOrderForm;
