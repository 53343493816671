import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker as MUIDatepicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

type DatePickerPropsType = DatePickerProps<Date>;

interface FormDatePickerProps extends Omit<DatePickerPropsType, 'value' | 'onChange'> {
	name: string;
	label: string;
	requiredField?: boolean;
	errorMessage?: string;
	onValueChange?: (value: Date | null) => void;
}

const DatePicker: React.FC<FormDatePickerProps> = ({
	name,
	label,
	requiredField,
	errorMessage,
	onValueChange,
	...rest
}) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	const hasError = !!errors[name] || !!errorMessage;
	const displayErrorMessage = errorMessage || (errors[name]?.message as string);

	return (
		<Controller
			name={name}
			control={control}
			rules={{
				required: requiredField && `${label ?? name} is required`,
			}}
			render={({ field: { value, onChange, ...fieldProps } }) => (
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<MUIDatepicker
						{...fieldProps}
						{...rest}
						value={value || null}
						onChange={(newValue) => {
							onChange(newValue);
							onValueChange?.(newValue);
						}}
						label={label}
						slotProps={{
							...rest.slotProps,
							textField: {
								...rest.slotProps?.textField,
								fullWidth: true,
								error: hasError,
								helperText: displayErrorMessage,
								sx: {
									'& .MuiOutlinedInput-root': {
										borderRadius: 3
									}
								}
							},
							popper: {
								...rest.slotProps?.popper,
								sx: {
									'& .MuiPaper-root': {
										borderRadius: 3,
										mt: 1
									}
								}
							}
						}}
					/>
				</LocalizationProvider>
			)}
		/>
	);
};

export default DatePicker;