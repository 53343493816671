import { useEffect, useMemo, useState } from "react";
import { OrderForm, PageState } from "./OrderFormInterfaces";
import useWebsocket from "../../../../../websocket/hooks/useWebsocket";

const useOrders = (initialID: number | undefined | null) => {
	const [data, setData] = useState<OrderForm>({
		page_state: {},
		permissions: {
			is_admin: false,
			is_superuser: false,
			view: false,
			create: false,
			edit: false,
			delete: false,
		},
		id: null,
		form: {
			number: {
				value: '',
				error: ''
			},
			parent_order: {
				value: '',
				error: ''
			},
			item: {
				value: '',
				error: ''
			},
			quantity: {
				value: 1,
				error: ''
			},
			due_date: {
				value: '',
				error: ''
			},
			ship_date: {
				value: '',
				error: ''
			},
		},
		items: [],
		orders: [],
		order_tree: {
			depth: 0, 
			tree: {}
		}, 
		version: 0
	});

	const { loading, sendMessage, isVisible, setPageState, isReady } = useWebsocket('/orders/form/', setData, { id: initialID });

	let pageState: PageState = data.page_state;
	const permissions = useMemo(() => data.permissions ? data.permissions : {
		is_admin: false,
		is_superuser: false,
		view: false,
		create: false,
		edit: false,
		delete: false,
	}, [data]);
	const items = useMemo(() => data.items, [data]);
	const orders = useMemo(() => data.orders, [data]);
	const form = useMemo(() => data.form, [data]);
	const id = useMemo(() => data.id, [data]);
	const orderTree = useMemo(() => data.order_tree, [data]);
	const version = useMemo(() => data.version, [data]);

	// Log and potentially re-set state when visibility changes
	useEffect(() => {
		if (!isVisible) {
			setPageState(pageState);
		}
	}, [isVisible]);

	return {
		loading,
		sendMessage,
		isVisible,
		setPageState,
		id,
		orders,
		items,
		form,
		orderTree,
		version,
		pageState,
		permissions,
		isReady
	};
};

export default useOrders;