import React, { FC, useState, useEffect } from 'react';
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Paper, Button, TablePagination, Box, CircularProgress, styled, Chip, Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as XLSX from 'xlsx';
import { Info } from '@mui/icons-material';

interface AccountingTableProps {
	data: ItemRow[];
}

interface ItemRow {
	'item': string;
	'description': string;
	'associated_orders': string[];
	'conforming_parts': string;
	'non_conforming_parts': string;
	'scrap_rate': string;
	'production_cost': string;
	'scrapped_production_cost': string;
	'sales_price': string;
	'scrapped_sales_price': string;
	'personnel'?: string[];
}

const HeaderCell = styled(TableCell)<{ selected: boolean }>(({ selected, theme }) => ({
	textAlign: 'center',
	fontWeight: 'bold',
	cursor: 'pointer',
	transition: 'background-color 0.5s, color 0.5s, box-shadow 0.3s',
	color: selected ? theme.palette.common.white : 'inherit',
	backgroundColor: selected ? theme.palette.primary.light : 'rgb(244, 249, 255)',
	padding: '16px',
	userSelect: 'none',
	'&:hover': {
		backgroundColor: theme.palette.primary.main,
	},
	position: 'sticky',
	top: 0,
	zIndex: 1,
}));

const AccountingTable: FC<AccountingTableProps> = ({ data }) => {
	const theme = useTheme();
	const [selectedHeaders, setSelectedHeaders] = useState<string[]>([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [loading, setLoading] = useState(true);

	const headers: { label: string, key: keyof ItemRow }[] = [
		{ label: 'Item', key: 'item' },
		{ label: 'Description', key: 'description' },
		{ label: 'Associated Orders', key: 'associated_orders' },
		{ label: 'Conforming Parts', key: 'conforming_parts' },
		{ label: 'Non Conforming Parts', key: 'non_conforming_parts' },
		{ label: 'Scrap Rate', key: 'scrap_rate' },
		{ label: 'Production Cost', key: 'production_cost' },
		{ label: 'Scrapped Production Cost', key: 'scrapped_production_cost' },
		{ label: 'Sales Price', key: 'sales_price' },
		{ label: 'Scrapped Sales Price', key: 'scrapped_sales_price' },
		{ label: 'Personnel', key: 'personnel' }
	];

	const handleHeaderClick = (header: string) => {
		setSelectedHeaders(prevSelectedHeaders =>
			prevSelectedHeaders.includes(header)
				? prevSelectedHeaders.filter(h => h !== header)
				: [...prevSelectedHeaders, header]
		);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleExport = () => {
		const workbook = XLSX.utils.book_new();
		const worksheetData = data.map(row =>
			headers.reduce((acc, header) => {
				if (selectedHeaders.includes(header.label)) {
					let value = row[header.key];
					if (header.key === 'scrap_rate' && typeof value === 'string') {
						value = value.replace('%', '')
					}
					acc[header.label] = Array.isArray(value) ? value.join(', ') : (value ?? '').toString();
				}
				return acc;
			}, {} as Record<string, string>)
		);
		const worksheet = XLSX.utils.json_to_sheet(worksheetData);
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Accounting Data');
		const timestamp = new Date().toLocaleString().replace(/[\/,:\s]/g, '_');
		const filename = `Accounting_Export_${timestamp}.xlsx`;
		XLSX.writeFile(workbook, filename);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(false);
		}, 2000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<div>
			{loading ? (
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '400px' }}>
					<CircularProgress />
				</Box>
			) : data.length === 0 ? (
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '400px' }}>
					<TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 350px)', overflowY: 'auto', borderRadius: 3 }}>
						<Table aria-label="accounting table" stickyHeader>
							<TableHead>
								<TableRow>
									{headers.map(header => (
										<HeaderCell
											key={header.key}
											selected={selectedHeaders.includes(header.label)}
											onClick={() => handleHeaderClick(header.label)}
											data-header={header.label}
										>
											{header.label}
										</HeaderCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell colSpan={headers.length} align="center">
										No Data Found
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			) : (
				<>
					<TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 350px)', overflowY: 'auto', borderRadius: 3 }}>
						<Table aria-label="accounting table" stickyHeader>
							<TableHead>
								<TableRow>
									{headers.map(header => (
										<HeaderCell
											key={header.key}
											selected={selectedHeaders.includes(header.label)}
											onClick={() => handleHeaderClick(header.label)}
											data-header={header.label}
										>
											{header.label}
										</HeaderCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
									<TableRow key={index}>
										{headers.map(header => (
											<TableCell key={header.key} sx={{ textAlign: 'center' }}>
												{
													Array.isArray(row[header.key]) ? (
														<>
															{(row[header.key] as string[]).slice(0, 5).map((value, i) => (
																<Chip variant='outlined' key={i} label={value} sx={{ margin: '2px' }} />
															))}
															{(row[header.key] as string[]).length > 5 && (
																<Chip
																	variant='outlined'
																	label={`+${(row[header.key] as string[]).length - 5}`}
																	sx={{ margin: '2px' }}
																/>
															)}
														</>
													) : (
														row[header.key]
													)
												}
											</TableCell>
										))}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>

						<Info sx={{ mr: 1 }} />
						<Typography sx={{ ml: 0 }}>
							Headers can be selected; only selected headers will be included in your export.
						</Typography>
						<TablePagination
							component="div"
							count={data.length}
							page={page}
							onPageChange={handleChangePage}
							rowsPerPage={rowsPerPage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							sx={{ml: 3}}
						/>
						<Button variant="contained" onClick={handleExport} sx={{ maxHeight: 30, ml: 3 }}>
							Export
						</Button>

					</Box>
				</>
			)}
		</div>
	);
};

export default AccountingTable;
