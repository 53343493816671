import React from 'react';
import { TextField as MUITextField, TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

interface FormTextFieldProps extends Omit<TextFieldProps, 'name' | 'error'> {
	name: string;
	requiredField?: boolean;
	errorMessage?: string;
	onValueChange?: (value: any) => void;
}

const TextField: React.FC<FormTextFieldProps> = ({
	name,
	requiredField,
	label,
	errorMessage,
	helperText,
	onValueChange,
	...rest
}) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	const hasError = !!errors[name] || !!errorMessage;
	const displayErrorMessage = errorMessage || (errors[name]?.message as string) || helperText;

	return (
		<Controller
			name={name}
			control={control}
			rules={{ required: requiredField && `${label ?? name} is required` }}
			render={({ field }) => (
				<MUITextField
					{...field}
					{...rest}
					label={label}
					error={hasError}
					helperText={displayErrorMessage}
					fullWidth
					onChange={(e) => {
						field.onChange(e);
						onValueChange?.(e.target.value);
					}}
					sx={{
						'& .MuiOutlinedInput-root': {
							borderRadius: 3
						}
					}}
				/>
			)}
		/>
	);
};

export default TextField;