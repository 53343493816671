import React from 'react';
import {
	Autocomplete as MUIAutocomplete,
	TextField,
	AutocompleteProps,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

// Basic interface for your options
export interface ItemOption {
	id: number;
	label: string;
	[key: string]: any;
}

// The props we accept, including M = boolean for multi/single
export interface FormAutocompleteProps<M extends boolean = false>
	extends Omit<AutocompleteProps<ItemOption, M, false, false>, 'renderInput' | 'options'> {
	name: string;
	label: string;
	options: ItemOption[];
	requiredField?: boolean;
	errorMessage?: string;
	multiple?: M;  // defaults to single-select
	onValueChange?: (value: M extends true ? ItemOption[] : ItemOption | null) => void;
}

function Autocomplete<M extends boolean = false>({
	name,
	label,
	options,
	requiredField,
	errorMessage,
	onValueChange,
	multiple = false as M,
	...rest
}: FormAutocompleteProps<M>) {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	const hasError = !!errors[name] || !!errorMessage;
	const displayErrorMessage = errorMessage || (errors[name]?.message as string);

	return (
		<Controller
			name={name}
			control={control}
			rules={{ required: requiredField && `${label} is required` }}
			render={({ field }) => (
				<MUIAutocomplete
					{...rest}
					multiple={multiple}
					options={options}
					getOptionLabel={(option) => option.label}
					isOptionEqualToValue={(option, value) => option.id === value?.id}
					// If multi-select, the value must be an array; otherwise a single object or null.
					value={field.value || (multiple ? [] : null)}
					onChange={(_, newValue) => {
						// Update form state
						field.onChange(newValue);
						// Fire user callback with properly typed value
						onValueChange?.(newValue as M extends true ? ItemOption[] : ItemOption | null);
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label={label}
							error={hasError}
							helperText={displayErrorMessage}
							sx={{
								'& .MuiOutlinedInput-root': {
									borderRadius: 3,
								},
							}}
						/>
					)}
					componentsProps={{
						popper: {
							modifiers: [
								{
									name: 'offset',
									options: {
										offset: [0, 8],
									},
								},
							],
							sx: {
								'& .MuiPaper-root': {
									borderRadius: 3,
									mt: 1,
								},
							},
						},
					}}
					sx={{
						'& .MuiOutlinedInput-root': {
							borderRadius: 3,
						},
					}}
				/>
			)}
		/>
	);
}

export default Autocomplete;
