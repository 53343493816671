import React from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	Box,
	Collapse,
	useTheme,
	Fab,
	Button,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { BomItem, OrderTree } from './hooks/OrderFormInterfaces';
import Legend from '../../../../utils/globalComps/Legend';

interface ChildOrderFormProps {
	orderTree: OrderTree;
	sendMessage: (type: string, payload?: any) => void; // allow optional payload
}

const ChildRow: React.FC<{
	itemName: string;
	item: BomItem;
	level: number;
}> = ({ itemName, item, level }) => {
	const [open, setOpen] = React.useState(false);
	const hasChildren = item.children && Object.keys(item.children).length > 0;
	const theme = useTheme();

	return (
		<>
			<TableRow
				sx={{
					// Example background: green if item.number is truthy, else red
					background: item.number
						? theme.palette.color.green
						: theme.palette.color.red,
				}}
			>
				{/* Narrow collapse button cell */}
				<TableCell align="center" sx={{ width: '1%', whiteSpace: 'nowrap', p: 0 }}>
					{hasChildren && (
						<Fab
							size="small"
							sx={{
								background: 'inherit',
								zIndex: 1,
								border: 2,
								borderColor: theme.palette.background.paper,
							}}
							onClick={() => setOpen(!open)}
						>
							{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
						</Fab>
					)}
				</TableCell>
				<TableCell align="center">{item.number || '-'}</TableCell>
				<TableCell align="center">{itemName}</TableCell>
				<TableCell align="center">{item.type}</TableCell>
				<TableCell align="center">{item.quantity}</TableCell>
			</TableRow>

			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
					<Collapse in={open} timeout={'auto'} unmountOnExit>
						<Paper
							sx={{
								my: 2,
								p: 0,
								borderRadius: 2,
								overflow: 'hidden',
								boxShadow: 2,
							}}
							variant="outlined"
						>
							<Table size="small" sx={{ borderCollapse: 'collapse' }}>
								<TableBody>
									{Object.entries(item.children ?? {}).map(([childName, childItem]) => (
										<ChildRow
											key={childName}
											itemName={childName}
											item={childItem}
											level={level + 1}
										/>
									))}
								</TableBody>
							</Table>
						</Paper>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

const ChildOrderForm: React.FC<ChildOrderFormProps> = ({ orderTree, sendMessage }) => {
	const theme = useTheme();

	if (!orderTree?.tree || Object.keys(orderTree.tree).length === 0) {
		return (
			<Box sx={{ p: 2, textAlign: 'center' }}>
				<Typography color="text.secondary">No child orders available</Typography>
			</Box>
		);
	}

	return (
		<Box sx={{ mt: 4 }}>
			{/* 
        Flex container for the heading and button 
      */}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					mb: 2,
					px: 2
				}}
			>
				<Box>
					<Typography variant="h6">
						Child Orders
					</Typography>
				</Box>
				<Box>
					<Legend legendItems={[
							{ color: theme.palette.color.green, text: 'Has Order' },
							{ color: theme.palette.color.red, text: 'No Order' },
						]} 
					/>
				</Box>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => sendMessage('build_child_orders')}
					sx={{ borderRadius: 3 }}
				>
					Build Child Orders
				</Button>
			</Box>

			<TableContainer component={Paper} sx={{ borderRadius: 2, boxShadow: 3, maxHeight: 300 }}>
				<Table size="medium" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell />
							<TableCell align="center">Number</TableCell>
							<TableCell align="center">Item</TableCell>
							<TableCell align="center">Type</TableCell>
							<TableCell align="center">Quantity</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{Object.entries(orderTree.tree).map(([itemName, item]) => (
							<ChildRow key={itemName} itemName={itemName} item={item} level={0} />
						))}
					</TableBody>
				</Table>
			</TableContainer>

		</Box>
	);
};

export default ChildOrderForm;
