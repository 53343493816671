import { useEffect, useMemo, useState } from "react";
import useWebsocket from "../../../../../websocket/hooks/useWebsocket";
import { format } from 'date-fns';
import { PrimarySchedule } from "./Interfaces";

const usePrimarySchedule = (identifier: string | number | null) => {
	const [data, setData] = useState<PrimarySchedule>({
		item: {
			name: '', 
			description: '',
			type: ''
		}, 
		primary_machine: {
			label: '', 
			type: -1,
			id: 0
		}, 
		primary_lineplans: [{
			label: '',
			type: -1,
			id: 0
		}],
		machines: [], 
		lineplans: []
	});
	const { loading, sendMessage, isVisible, setPageState } = useWebsocket('/scheduling/primary-schedule-form/', setData, { identifier });

	const item = data.item
	const primaryMachine = data.primary_machine
	const primaryLineplans = data.primary_lineplans
	const machines = data.machines
	const lineplans = data.lineplans

	return {
		item, 
		primaryMachine, primaryLineplans, 
		machines, lineplans,
		loading, sendMessage
	};
};

export default usePrimarySchedule;
